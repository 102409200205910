@font-face {
  font-family: GothamotBook;
  src: url(assets/font/gothamot_book-webfont.woff);
}

@font-face {
  font-family: GothamotBook;
  src: url(assets/font/gothamot_medium-webfont.woff);
  font-weight: bold;
}

* {
  font-family: GothamotBook, serif;

  }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #A19F9F;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #FFFFFF;
}

.guacamole-display:focus-visible {
  outline: none;
}

.guacamole-display canvas {
  z-index: 0!important;
}
@primary-color: #175D5C;@layout-body-background: #F1F1F1;@layout-header-background: #175D5C;@layout-trigger-background: #175D5C;@menu-bg: #175D5C;@menu-inline-submenu-bg: #175D5C;@menu-item-color: #FFFFFF;@menu-highlight-color: #FFFFFF;@menu-item-active-bg: #00B695;