@import "../../../assets/less/color.less";

.ant-breadcrumb {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  color: @primary;
  display: flex;
  align-items: center;
}

.last-breadcrumb {
  color: @primary;
  font-weight: bold;
}

.ant-breadcrumb a {
  color: @primary;
  transition: color 0s;
}

.ant-breadcrumb-separator {
  color: @primary;
}

.ant-breadcrumb > span:last-child a {
  color: @primary;
  font-weight: bold;
}

@primary-color: #175D5C;@layout-body-background: #F1F1F1;@layout-header-background: #175D5C;@layout-trigger-background: #175D5C;@menu-bg: #175D5C;@menu-inline-submenu-bg: #175D5C;@menu-item-color: #FFFFFF;@menu-highlight-color: #FFFFFF;@menu-item-active-bg: #00B695;