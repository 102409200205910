.fc table {
    font-size: 13px;
}

.fc .fc-button {
    font-size: 13px;
}

.fc .fc-toolbar-title {
    font-size: 20px;
    font-weight: bold;
}

.fc-daygrid-event-dot {
    display: none;
}

.fc-event-time {
    margin-left: 5px;
}

.fc-h-event .fc-event-time {
    overflow: initial;
}

.fc .fc-button-primary, .fc .fc-button-primary:disabled {
    background-color: #175D5C;
    border-color: #175D5C;
}

.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:hover {
    background-color: #00b695;
    border-color: #00b695;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: rgba(110, 192, 236, 0.3);
}

.fc .fc-scrollgrid-section .fc-day-today {
    background-color: rgba(110, 192, 236, 0.3);
}

.fc-theme-standard .fc-scrollgrid {
    border: 0;
}

.fc-theme-standard td, .fc-theme-standard th {
    border: 0;
    border-top: 1px solid #f0f0f0;
}

.fc .fc-daygrid-event-harness {
    overflow: hidden;
}

.calendar-container {
    max-width: 1000px;
    margin: 0 auto;
}

/* CALENDAR STYLES */

.calendar-container .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.ant-picker-cell.ant-picker-cell-in-view .calendar-btn {
    display: inline-block;
}

.ant-picker-cell .calendar-btn {
    display: none;
}

.calendar-btn, .calendar-btn:focus {
    background: transparent;
    border: 1px solid rgb(88, 182, 231)!important;
    color: rgb(88, 182, 231)!important;
    border-radius: 3px;
    max-width: 45px;
    margin-bottom: 5px;
}

.calendar-btn:hover {
    background: rgb(88, 182, 231);
    color: white;
}

.calendar-btn:hover span {
    color: white;
}

.calendar-btn span {
    font-size: 11px;
    display: block;
}

.calendar-container .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    content: 'TODAY';
    color: rgb(88, 182, 231);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

/* style for empty dates */
.calendar-container .ant-picker-cell-in-view .empty {
    border-top: 1px solid #f0f0f0;
}

/* style for free dates */
.calendar-container .ant-picker-cell-in-view .free {
    border-top: 2px solid #17b695;
    color: #17b695;
    background: #bff7eb!important;
}

/* style for unavailable dates */
.calendar-container .ant-picker-cell-in-view .busy {
    border-top: 2px solid #b2283c;
    color: #b2283c;
    background: #ffc5cd!important;
}

/* style for today */
.calendar-container .ant-picker-cell-today > .ant-picker-cell-inner > .ant-picker-calendar-date-value {
    border-bottom: 2px solid rgb(88, 182, 231);
    background-color: transparent;
}

@media (max-width: 425px) {
    .dropdowns {
        flex-direction: column;
    }

    .dropdowns .ant-input {
        margin-bottom: 5px;
    }

    .availability-container .ant-select.ant-select-single.ant-select-show-arrow.ant-select-show-search {
        width: 300px;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    background: transparent;
    border: 0;
}
