@import '~antd/dist/antd.less';

#root {
  min-height: 100vh;
  position: relative;
}

#root > .animated-logo {
  position: absolute;
  display: block;
  width: 120px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-table-column-title {
  color: #175d5c;
  font-weight: 700;
}

.ant-table-thead > tr > th {
  color: #175d5c;
  font-weight: 700;
}

.full-width {
  width: 100%;
}

.max-w-300 {
  width: 100%;
  max-width: 300px;
}
.ant-card.filter-card > .ant-card-body {
  padding: 12px 24px;
}

.m-0 {
  margin: 0 !important;
}

.m-t-8 {
  margin-top: 8px !important;
}

.m-b-8 {
  margin-bottom: 8px !important;
}

.m-l-8 {
  margin-left: 8px !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.p-0-25 {
  padding: 0 25px;
}

.p-25-0 {
  padding: 25px 0;
}

.p-50-0 {
  padding: 50px 0;
}

.gap-8 {
  gap: 8px;
}

.flex {
  display: flex !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.max-height {
  min-height: calc(100vh - 222px);
}

.pointer {
  cursor: pointer;
}

.remove-row {
  width: 100%;
  display: flex;
}

.remove-item {
  width: 100%;
  margin-bottom: 0;
}

.remove-btn {
  margin-left: 10px;
  margin-top: 8px;
}

.ant-form {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.ant-list-pagination {
  text-align: center;
}

.ant-card-actions > li > span a:not(.ant-btn) {
  color: white;
}

.ant-card-actions > li > span > .ant-btn-primary:hover > a {
  color: white !important;
}

.credential-table-left {
  font-weight: bold;
  width: 12em;
}

.credential-table-right {
  min-width: none;
}

@primary-color: #175D5C;@layout-body-background: #F1F1F1;@layout-header-background: #175D5C;@layout-trigger-background: #175D5C;@menu-bg: #175D5C;@menu-inline-submenu-bg: #175D5C;@menu-item-color: #FFFFFF;@menu-highlight-color: #FFFFFF;@menu-item-active-bg: #00B695;