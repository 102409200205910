@import "../../../assets/less/color.less";

.ant-layout {
  background: @background;
}

.main-container {
  padding: 0 25px 20px;
}

.timezone-wrapper {
  width: 220px;
}

@primary-color: #175D5C;@layout-body-background: #F1F1F1;@layout-header-background: #175D5C;@layout-trigger-background: #175D5C;@menu-bg: #175D5C;@menu-inline-submenu-bg: #175D5C;@menu-item-color: #FFFFFF;@menu-highlight-color: #FFFFFF;@menu-item-active-bg: #00B695;