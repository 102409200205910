@import "../../../assets/less/color.less";

.ant-layout-header {
  color: @primary;
  padding: 0 25px;
  background: @white;
}

.avatar-icon {
  margin-right: 10px;
}

.chevron-icon {
  margin-left: 10px;
}

.nil-logo-container {
  display: flex;
  height: 100%;
  padding: 5px 0;
}

.nil-logo {
  height: 100%;
}

.profile-dropdown {
  padding: 0 15px;
}

@primary-color: #175D5C;@layout-body-background: #F1F1F1;@layout-header-background: #175D5C;@layout-trigger-background: #175D5C;@menu-bg: #175D5C;@menu-inline-submenu-bg: #175D5C;@menu-item-color: #FFFFFF;@menu-highlight-color: #FFFFFF;@menu-item-active-bg: #00B695;