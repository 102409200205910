.time-zone {
  display: flex;
  align-items: center;
  width: 100%;
}

.time-zone-select {
  width: 100%;
}

@primary-color: #175D5C;@layout-body-background: #F1F1F1;@layout-header-background: #175D5C;@layout-trigger-background: #175D5C;@menu-bg: #175D5C;@menu-inline-submenu-bg: #175D5C;@menu-item-color: #FFFFFF;@menu-highlight-color: #FFFFFF;@menu-item-active-bg: #00B695;